import Cfg from './Cfg.js';

class Localizator {

    translate(lang, s) {
        var result = '';

        if(typeof s.name !== 'string') {
            if (s[lang]) {
                result = s[lang];
            }
            else {
                var languages = Cfg.getLanguages();

                languages.forEach(
                    function(language) {
                        if (!result) {
                            result = s[language];
                        }
                    }
                );
            }
        }
        else {
            result = s;
        }

        return result;
    }

    translateMarkdown(lang, s) {
        var result = "";

        var translations = s.split(/\[language\]:/);

        if (translations.length === 1) {
            result = translations[0];
        }
        else {
            result = "";

            for (var i = 1; i < translations.length; i++) {
                var language = translations[i].match(/^\s*([a-z]+)/)[1];

                if (lang === language) {
                    result = this._sanitizeMarkdown(translations[i]);
                    break;
                }
            }

            if (!result) {
                translations.map(
                    (markdown) => {
                        if (!result && markdown) {
                            result = this._sanitizeMarkdown(markdown);
                        }

                        return 1;
                    }
                );

            }
        }

        return result;
    }

    _sanitizeMarkdown(markdown) {
        return markdown.replace(/^\s*[a-z]{2}\s*\n/g, "");
    }
}

const localizator = new Localizator();

export default localizator;
