import cfg           from './cfg.json';
import Cookies       from 'js-cookie';
import getUserLocale from 'get-user-locale';


class Cfg {
    getPage() {
        var page = this._getPageByUrlHash() || this._getPageByDefault();

        return page;
    }

    setPage(required_page_name) {
        var current_page_name = this._getPageByUrlHash();

        if (current_page_name !== required_page_name) {
            window.location.hash = '#' + required_page_name;
        }
    }

    getPages() {
        return cfg.pages;
    }

    isUserRobot() {
        var userAgent = navigator.userAgent;

        const robots = new RegExp(
            [
                /bot/,/spider/,/crawl/,                      // GENERAL TERMS
                /APIs-Google/,/AdsBot/,/Googlebot/,          // GOOGLE ROBOTS
                /mediapartners/,/Google Favicon/,
                /FeedFetcher/,/Google-Read-Aloud/,
                /DuplexWeb-Google/,/googleweblight/,
                /bing/,/yandex/,/baidu/,/duckduck/,/yahoo/,  // OTHER ENGINES
                /ecosia/,/ia_archiver/,
                /facebook/,/instagram/,/pinterest/,/reddit/, // SOCIAL MEDIA
                /slack/,/twitter/,/whatsapp/,/youtube/,
                /semrush/,                                   // OTHER
            ].map((r) => r.source).join("|"),"i"             // BUILD REGEXP + "i" FLAG
        );

        return robots.test(userAgent);
    }

    getLanguage() {
        if (this.isUserRobot()) {
            return this._getDefaultLanguage();
        }

        return 0
            || this._checkLanguage(this._getLanguageFromCookie())
            || this._checkLanguage(this._getLanguageFromUserLocale())
            || this._getDefaultLanguage();
    }

    setLanguage(language) {
        this._setCookie('language', language);
        this.setPage(this.getPage(), language);
    }

    getLanguages() {
        return Object.keys(cfg.languages);
    }

    getLanguagesHash() {
        return cfg.languages;
    }

    _getPageByName(page_name) {
        if (!page_name) {
            return '';
        }

        page_name = page_name.replace('%2F', '/');

        var pages        = this.getPages();
        var hidden_pages = this._getHiddenPages();

        pages = pages.concat([hidden_pages]);

        var page;

        pages.map(
            function(items) {
                items.map(
                    function(item) {
                        if (Object.values(item.name).includes(page_name)) {
                            page = item;
                        }

                        return item;
                    }
                );

                return items;
            }
        )

        return page;
    }

    _getPageByUrlHash() {
        var url_hash = window.location.hash.replace('#', '');

        var current_page_name = url_hash ? decodeURI(url_hash) : '';
        var page              = this._getPageByName(current_page_name);

        return page;
    }

    _getPageByDefault() {
        return this._getPageByName(this._getDefaultPageName());
    }

    _getLanguageFromCookie() {
        return this._getCookie('language');
    }

    _getLanguageFromUserLocale() {
        var locale = getUserLocale().match(/^(.{2})/).[0];

        return locale;
    }

    _getDefaultLanguage() {
        return this.getLanguages()[0];
    }

    _checkLanguage(lang) {
        if (this.getLanguages().includes(lang)) {
            return lang;
        }
        else {
            return '';
        }
    }

    _getCookie(key) {
        return Cookies.get(key);
    }

    _setCookie(key, value) {
        return Cookies.set(key, value, {expires: 365});
    }

    _getDefaultPageName() {
        return cfg.default_page_name;
    }

    _getHiddenPages() {
        return cfg.hidden_pages;
    }
}

export default new Cfg();
