import React, {Suspense} from 'react';

import Wait        from './Rack/Widget/Wait.js';
import Cfg         from './Cfg.js';
import localizator from './Localizator.js';

const Page = React.lazy(() => import('./Rack/Page.js'));


class App extends React.Component {

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll, true);
    }

    constructor(props) {
        super(props);

        this.setPage        = this.setPage.bind(this);
        this.setLanguage    = this.setLanguage.bind(this);
        this.handleScroll   = this.handleScroll.bind(this);
        this.handleUnScroll = this.handleUnScroll.bind(this);
        this.refreshPage    = this.refreshPage.bind(this);

        var page      = Cfg.getPage();
        var language  = Cfg.getLanguage();

        Cfg.setPage(localizator.translate(language, page.name));

        this.state = {
            page:     page,
            language: language,
            scrolled: false,
        }
    }

    handleScroll(e) {
        if (window.scrollY === 0 && this.state.scrolled === true) {
            this.setState({scrolled: false});
        }

        // Должно соответствовать суффиксам в NavBar.js (16,17,20,21)
        // https://getbootstrap.com/docs/4.0/layout/grid/
        if (window.innerWidth < 1200 && window.scrollY > 10 && this.state.scrolled === false) {
            this.setState({scrolled: true});
        }
    }

    handleUnScroll(e) {
        this.setState({scrolled: false});
    }

    setPage(e, page) {
        this.setState({page: page});
    }

    setLanguage(e, language) {
        Cfg.setLanguage(language);

        this.setState({language: language});
    }

    refreshPage() {
        this.setPage(
            1, Cfg._getPageByName(document.getElementById('refresh-btn').value)
        )
    }

    render() {
        return (
            <div className="App container-fluid">
                <input
                    type="hidden"
                    id="refresh-btn"
                    onClick={this.refreshPage}
                />
                <Suspense fallback={<Wait />}>
                    <Page
                        refreshPage    = {this.refreshPage}
                        setPage        = {this.setPage}
                        setLanguage    = {this.setLanguage}
                        language       = {this.state.language}
                        scrolled       = {this.state.scrolled}
                        handleUnScroll = {this.handleUnScroll}
                        page           = {this.state.page}
                    />
                </Suspense>
            </div>
        );
    }
}

export default App;
